@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy";
  src: url("../components/asset/gilroy-font/Gilroy-Bold.woff2") format("woff2"),
    url("../components/asset/gilroy-font/Gilroy-Bold.woff") format("woff"),
    url("../components/asset/gilroy-font/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../components/asset/gilroy-font/Gilroy-Semibold.woff2")
      format("woff2"),
    url("../components/asset/gilroy-font/Gilroy-Semibold.woff") format("woff"),
    url("../components/asset/gilroy-font/Gilroy-Semibold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../components/asset/gilroy-font/Gilroy-Regular.woff2")
      format("woff2"),
    url("../components/asset/gilroy-font/Gilroy-Regular.woff") format("woff"),
    url("../components/asset/gilroy-font/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
:focus-visible {
  outline: none;
}


.modal-terms-wrapper{
  @apply z-50 popup-modal bg-white  h-full  w-full max-h-[70%] max-w-[calc(100%-30px)]  md:w-[612px] xl:w-[980px] fixed ;
  position: fixed;
 }
 .modal-terms-wrapper-close{
   @apply bg-white rounded-full py-[4px] px-[4px] shadow-md absolute ltr:right-0 rtl:left-0 top-0 z-10 translate-x-1/2 -translate-y-1/2;
  }
 
  .modal-licence-wrapper .modal-terms-wrapper,
  #popup_licence_change_body .modal-terms-wrapper{
   @apply max-h-full absolute  lg:fixed;
   max-width: 100%;
   width:100%;
  }
  .modal-licence-wrapper .modal-terms-wrapper-close,
  #popup_licence_change_body .modal-terms-wrapper-close{
   @apply -translate-x-[10%] translate-y-[10%];
  }

.orRegister:after {
  content: "";
  position: absolute;
  height: 0.5px;
  width: 100%;
  background: #999999;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.rightContainer .complete-later {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  white-space: nowrap;
}
.box-shadow {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.load-shadow {
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.05);
}
.popup-modal {
  left: 50%;
  top: 50%;
  border-radius: 10px;
  @apply -translate-x-1/2 -translate-y-1/2;
}
.line-background {
  background-image: url("../public/box-background.png");
  background-size: cover;
  background-position: center center;
}
.gray-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 40;
}
#book-a-call-modal .gray-overlay {
  z-index: 50;
}
.dotted-border {
  background-image: url("../public/dotted-border.png");
  background-size: 99% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.mainContainer .progressSteps .divider span {
  margin-bottom: 30px;
}
.green-check:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  right: 7px;
  top: 12px;
  background-image: url("../public/tick-circle.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 10;
}
.delete-icon:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  right: 33px;
  top: 12px;
  background-image: url("../public/delete-icon.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 10;
}
.eye-icon:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  right: 13px;
  top: 14px;
  background-image: url("../public/eye-icon.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 10;
}
.loader {
  width: 31px;
  height: 31px;
  padding: 5px;
  background: #0995dd;

  aspect-ratio: 1;
  border-radius: 50%;
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load 1s linear infinite;
}

.invalidInput {
  border: 1px solid red;
}
.invalidLabel {
  color: red;
}

.parse-links-in-text a {
  color: #0995dd;
}

.parse-links-in-text a:hover {
  text-decoration: underline;
}

@keyframes load {
  to {
    transform: rotate(1turn);
  }
}
.full-dropdown span.truncate {
  width: 100%;
}
/* Change the white to any color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    --tw-text-opacity: 1;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    color: rgb(78, 78, 78) !important;
    -webkit-text-fill-color: rgb(78, 78, 78) !important;
    background-clip: content-box !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-background-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: white !important;
  background-color: white !important;
  background-clip: content-box !important;
  color: rgb(78, 78, 78) !important;
  -webkit-text-fill-color: rgb(78, 78, 78) !important;
}

.loadingcenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-ms-reveal {
  display: none;
}

.tooltip {
  display: inline-block;
  position: relative;
  @apply ltr:text-left rtl:text-right;
}

.tooltip .top {
  min-width: 250px;
  max-width: 400px;
  top: -20px;
  padding: 10px 20px;
  color: #707070;
  background-color: #fff;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1.3;
  border-radius: 5px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  display: none;
  @apply ltr:left-1/2 rtl:right-1/2 ltr:-translate-x-[88%] rtl:translate-x-[88%] -translate-y-full;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  width: 30px;
  height: 15px;
  overflow: hidden;
  @apply ltr:-ml-[15px] rtl:-mr-[15px] ltr:right-[7%] rtl:left-[7%];
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px) {
  .tooltip .top {
    @apply ltr:-translate-x-[96%] rtl:translate-x-[96%];
  }
  .tooltip .top i {
    @apply ltr:-right-[1%] rtl:-left-[1%];
  }
  .centerMobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tooltipClose {
  display: inline-block;
  position: relative;
}

.tooltipClose .bottom {
  min-width: 415px;
  max-width: 415px;
  top: 80px;
  right: -15%;
  padding: 20px 35px;
  color: #050505;
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
  border-radius: 5px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  display: none;
}

.tooltipClose:hover .bottom {
  display: block;
}

.tooltipClose .bottom img {
  width: 400px;
}

.tooltipClose .bottom i {
  position: absolute;
  bottom: 100%;
  right: 8px;
  margin-left: -12px;
  width: 24px;
  height: 20px;
  overflow: hidden;
}

.tooltipClose .bottom i::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #fff;
  box-shadow: 0 1px 8px rgb(0 0 0 / 15%);
}

@media screen and (max-width: 576px) {
  .tooltipClose .bottom {
    min-width: 310px;
    max-width: 310px;
  }
}

.qrCodeImage img {
  @apply max-w-[none] w-[160px] lg:w-[210px];
}

.stepsSeparator {
  height: 0.875rem;
  display: inline-block;
  width: 1px;
  background-color: #0995dd;
  margin-top: 0.8rem;
  margin-bottom: 0.6rem;
  @apply ltr:ml-[0.75rem] rtl:mr-[0.75rem];
}
.screenImage {
  position: absolute;
  bottom: 0%;
  width: 48%;
  height: auto;
  @apply ltr:right-0 rtl:left-0;
}
@media screen and (max-width: 1200px) {
  .screenImage {
    bottom: 15%;
  }
}
@media screen and (max-width: 768px) {
  .screenImage {
    display: none;
  }
}
.custom-code {
  letter-spacing: 60px;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.react-datepicker {
  background-color: #fff !important;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker__day.react-datepicker__day--selected {
  background-color: rgb(9, 149, 221) !important;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: calc(100% / 6 - 14px);
  --ReactInputVerificationCode-itemHeight: 50px;
  --ReactInputVerificationCode-itemSpacing: 16px;
}
@media screen and (max-width: 768px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: calc(100% / 6 - 9px);
    --ReactInputVerificationCode-itemHeight: 42px;
    --ReactInputVerificationCode-itemSpacing: 10px;
  }
}
@media screen and (max-width: 320px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: calc(100% / 6 - 9px);
    --ReactInputVerificationCode-itemHeight: 35px;
    --ReactInputVerificationCode-itemSpacing: 10px;
  }
}

.custom-styles .ReactInputVerificationCode-container {
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item {
  box-shadow: none;
  border: 1px solid #ccc;
}
.custom-styles .ReactInputVerificationCode__item.is-active {
  border: 2px solid #999999;
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:last-child {
  margin-right: 0;
}

.custom-styles .ReactInputVerificationCode__item:after {
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}

.word-break {
  word-break: break-word;
}

.divide-color {
  background-color: #0995dd;
}

.background-box-selected {
  background-color: rgba(9, 149, 221, 0.06);
}

.navArrow {
  @apply h-[22px] md:h-[46px] relative top-[-7px] rtl:rotate-180;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

button.MuiPickersDay-root {
  font-size: 1rem !important;
}
.MuiPickersArrowSwitcher-root .MuiSvgIcon-root {
  @apply rtl:rotate-180;
}

.confetyImg {
  max-width: none;
  height: auto;
}

#cloudflare-captcha-widget,
#cloudflare-captcha-widget iframe {
  @apply w-full !important;
}
/* .arrowWiggle {
  animation: wiggle 1.5s ease-in-out infinite;
  
}

@keyframes wiggle {
  0% {
    left: -2px;
  }
  50% {
    left: 2px;
  }
  100% {
    left: -2px;
  }
} */

.heartbeat {
  -webkit-animation: heartbeat 1s ease-in-out 0.4s both;
  animation: heartbeat 1s ease-in-out 0.4s both;
}

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@media (max-width: 767px),(max-height: 699px) {
  .fixed-button-wrapper{
    @apply min-h-[60px]
  }
  .fixed-button {
    @apply fixed bottom-0 w-full left-0 py-4 px-5 bg-white  z-[20] mt-0;
    box-shadow: 0px -5px 12px 0px rgba(0,0,0,0.04);
  }
  .fixed-button button {
    margin: 0;
  }
}

@media (max-height: 699px) and (min-width: 1280px) {
  html, body{
    @apply text-sm;
  }
}


.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 0;
  height: 100vh !important;
  position: relative;
}

.swiper-slide img {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
}

.swiper-text {
  position: relative;
  z-index: 10;
  color: white;
  padding: 25% 10%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.slide-three .swiper-text {
  justify-content: flex-end !important;
  padding: 30% 10%;
}

.swiper-text.end {
  justify-content: flex-end;
}

.swiper-text h3 {
  font-size: 2rem;
  margin-bottom: 2.5rem;
  line-height: 1.3;
  text-transform: unset;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
}

.swiper-text h3 strong {
  font-weight: 800;
  text-transform: uppercase;
}

.swiper-text p {
  line-height: 1.5;
  font-size: 16px;
}

.swiper-text p strong {
  font-weight: 700;
}

@media (max-width: 1700px) {
  .swiper-text h3 {
      font-size: 1.8rem;
  }
}

@media (max-width: 1279px) {
  .swiper-text h3 {
      font-size: 1.5rem;
  }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 3% !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background: white !important;
  margin: 0 15px !important;
}

.swiper-button-prev {
  height: 1.77rem !important;
  width: 1.77rem !important;
  border-radius: 50% !important;
  background-color: #e2e2e2 !important;
  text-indent: 100% !important;
  -webkit-transition: background-color .2s ease-in-out !important;
  transition: background-color .2s ease-in-out !important;
  z-index: 1 !important;
  bottom: 3% !important;
  top: auto !important;
  left: 20% !important;
}

.swiper-button-next {
  height: 1.77rem !important;
  width: 1.77rem !important;
  border-radius: 50% !important;
  background-color: #e2e2e2 !important;
  -webkit-transition: background-color .2s ease-in-out !important;
  transition: background-color .2s ease-in-out !important;
  z-index: 1 !important;
  bottom: 3% !important;
  top: auto !important;
  right: 20% !important;
}

.swiper-btn-prev-rtl.swiper-button-prev {
  position: absolute !important;
  height: 1.77rem !important;
  width: 1.77rem !important;
  border-radius: 50% !important;
  background-color: #e2e2e2 !important;
  text-indent: 100% !important;
  -webkit-transition: background-color .2s ease-in-out !important;
  transition: background-color .2s ease-in-out !important;
  z-index: 1 !important;
  bottom: 3% !important;
  left: auto !important;
  right: 20% !important;
  top: auto !important;
  rotate: 180deg !important;
}

.swiper-btn-next-rtl.swiper-button-next {
  position: absolute !important;
  height: 1.77rem !important;
  width: 1.77rem !important;
  border-radius: 50% !important;
  background-color: #e2e2e2 !important;
  -webkit-transition: background-color .2s ease-in-out !important;
  transition: background-color .2s ease-in-out !important;
  z-index: 1 !important;
  bottom: 3% !important;
  right: auto !important;
  left: 20% !important;
  rotate: 180deg !important;
}

/* Default LTR Styles */
.swiper-button-next:after {
  content: '' !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 0.25rem solid transparent !important;
  border-bottom: 0.25rem solid transparent !important;
  border-left: 0.325rem solid #646464 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: border-color .2s ease-in-out !important;
  margin-left: 1px !important;
}

.swiper-button-prev:after {
  content: '' !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 0.25rem solid transparent !important;
  border-bottom: 0.25rem solid transparent !important;
  border-right: 0.325rem solid #646464 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: border-color .2s ease-in-out !important;
  margin-left: -1px !important;
}

.title-div {
  text-align: center;
  margin-bottom: 1rem;
}

.title-div h2 {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 1rem;
}

.button-wrapper-swiper {
  border-radius: 29px;
  background-color: #ff4848;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 16px;
  border: 0;
  transition: all 200ms ease-in-out;
}

.button-wrapper-swiper:hover {
  background: rgba(242, 55, 55, 1);
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

.button-wrapper-div {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.button-wrapper-div.onmobile {
  display: none;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-button-next-rtl,
.swiper-button-prev-rtl {
  z-index: 10 !important;
}

@media (max-height:740px) {
  .swiper-text h3 {
      margin-bottom: 1.5rem;
  }
}
